/* eslint-disable no-loop-func */
import * as auth0 from "auth0-js";
import { AUTH_CONFIG } from "./auth0-variables";

export default class Auth {
    public auth0: auth0.WebAuth;
    public profile: auth0.Auth0UserProfile;
    public idToken: string;
    public accessToken: string;
    public expiresAt: number;    

    constructor() {
        this.getProfile = this.getProfile.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.login = this.login.bind(this);
        this.loginEmail = this.loginEmail.bind(this);


        this.loginSAMLProdEu = this.loginSAMLProdEu.bind(this);
        this.loginSAMLQaEu = this.loginSAMLQaEu.bind(this);

        this.loginSAMLQaNa = this.loginSAMLQaNa.bind(this);
        this.loginSAMLProdNa = this.loginSAMLProdNa.bind(this);

        this.loginSAMLProdAu = this.loginSAMLProdAu.bind(this);
        this.loginSAMLQaAu = this.loginSAMLQaAu.bind(this);

        this.loginSAMLProdAsia = this.loginSAMLProdAsia.bind(this);
        this.loginSAMLQaAsia = this.loginSAMLQaAsia.bind(this);





        //this.loginSAML = this.loginSAML.bind(this);
        //this.loginSAMLNA = this.loginSAMLNA.bind(this);
        //this.loginSAMLProdNa = this.loginSAMLProdNa.bind(this);
        //this.loginSAMLProdAU = this.loginSAMLProdAU.bind(this);
        //this.loginSAMLDevAU = this.loginSAMLDevAU.bind(this);
        //this.loginSAMLProdAsia = this.loginSAMLProdAsia.bind(this);
        this.logout = this.logout.bind(this);
        this.setSession = this.setSession.bind(this);
        const arr = window.location.href.split("/");
        var redirectUri = arr[0] + "//" + arr[2] + "/callback";

        if (window.location.href.includes("emailreports")) {
            redirectUri = arr[0] + "//" + arr[2] + "/email";
        } 
        this.auth0 = new auth0.WebAuth({
            // the following three lines MUST be updated
            audience: AUTH_CONFIG.audience,
            clientID: AUTH_CONFIG.clientId,
            domain: AUTH_CONFIG.domain,
            redirectUri, // AUTH_CONFIG.callbackUrl,
            responseType: "token id_token",
            scope: AUTH_CONFIG.scope
        });
        this.profile = {} as auth0.Auth0UserProfile;
        this.idToken = "";
        this.accessToken = "";
        this.expiresAt = 0;
    }

    public getProfile() {
        return this.profile;
    }

    public async handleAuthentication() {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash((err, authResult) => {
                if (err) {
                    return reject(err);
                }
                if (!authResult || !authResult.idToken) {
                    return reject(err);
                }
                this.setSession(authResult);
                resolve(authResult);
            });
        });
    }

    public isAuthenticated() {
        return new Date().getTime() < (this.expiresAt as number);
    }

    public async loginEmail(parameters: string) {
        await this.auth0.authorize({ state: parameters });
    }
     
    
    public async login() {

        if (window.location.href.includes("sharedDashboard")) {
            const arr = window.location.href.split("/");
            localStorage.setItem('emailedDashboardUid', arr[4]);
        }
        else {
            localStorage.removeItem('emailedDashboardUid');
        }
        await this.auth0.authorize();
    }


    //For Europe
    public async loginSAMLProdEu() {
        return await this.auth0.authorize({
            connection: AUTH_CONFIG.samlConnectionProdEu
        });
    }

    public async loginSAMLQaEu() {
        return await this.auth0.authorize({
            connection: AUTH_CONFIG.samlConnectionQaEu
        });
    }



    //For North America 
    public async loginSAMLQaNa() {
        return await this.auth0.authorize({
            connection: AUTH_CONFIG.samlConnectionQaNa
        });
    }
    public async loginSAMLProdNa() {
        return await this.auth0.authorize({
            connection: AUTH_CONFIG.samlConnectionProdNa
        });
    }

    // For Australia
    public async loginSAMLProdAu() {
        return await this.auth0.authorize({
            connection: AUTH_CONFIG.samlConnectionProdAu
        });
    }

    public async loginSAMLQaAu() {
        return await this.auth0.authorize({
            connection: AUTH_CONFIG.samlConnectionQaAu
        });
    }

    //For Asia
    public async loginSAMLProdAsia() {
        return await this.auth0.authorize({
            connection: AUTH_CONFIG.samlConnectionProdAsia
        });
    }

    public async loginSAMLQaAsia() {
        return await this.auth0.authorize({
            connection: AUTH_CONFIG.samlConnectionQaAsia
        });
    }




    ////For Europe
    //public async loginSAML() {
    //    return await this.auth0.authorize({
    //        connection: AUTH_CONFIG.samlConnection
    //    });
    //}


    ////For North America 
    //public async loginSAMLNA() {
    //    return await this.auth0.authorize({
    //        connection: AUTH_CONFIG.samlConnectionNA
    //    });
    //}    
    //public async loginSAMLProdNa() {
    //    return await this.auth0.authorize({
    //        connection: AUTH_CONFIG.samlConnectionProdNa
    //    });
    //}

    //// For Australia
    //public async loginSAMLProdAU() {
    //    return await this.auth0.authorize({
    //        connection: AUTH_CONFIG.samlConnectionProdAu
    //    });
    //}

    //public async loginSAMLDevAU() {
    //    return await this.auth0.authorize({
    //        connection: AUTH_CONFIG.samlConnectionDevAU
    //    });
    //}

    ////For Asia
    //public async loginSAMLProdAsia() {
    //    return await this.auth0.authorize({
    //        connection: AUTH_CONFIG.samlConnectionProdAsia
    //    });
    //}



    public async logout() {
        // clear id token and expiration
        this.idToken = "";
        this.accessToken = "";
        this.expiresAt = 0;
        this.profile = {} as auth0.Auth0UserProfile;
        await this.auth0.logout({
            returnTo: window.location.origin
        });
    }

    public setSession(authResult: auth0.Auth0DecodedHash) {
        this.idToken = authResult.idToken as string;
        this.accessToken = authResult.accessToken as string;
        this.profile = authResult.idTokenPayload;
        // set the time that the id token will expire at
        this.expiresAt =
            (authResult.expiresIn as number) * 1000 + new Date().getTime();       
    }
}
